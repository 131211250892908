import React, { useEffect, useState } from 'react'
import _ from 'lodash';
import {
    GridLayout,
    GridLayoutItem,
} from "@progress/kendo-react-layout";
import SFPieChart from '../sfPieChart';
import SFBarChart from '../sfBarChart';
import axios from "../../../common/axios"
import { useParams } from 'react-router-dom';

import {
    IntlProvider,
    LocalizationProvider,
    loadMessages,
} from "@progress/kendo-react-intl";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import loadCldrData from '../../common/loadCldrData';
import krMessages from "../../common/kr.json";

import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { ComboBox } from '@progress/kendo-react-dropdowns';
import { DropDownList } from '@progress/kendo-react-dropdowns';


loadCldrData();
loadMessages(krMessages, "ko-KR");

const ToggleButton2 = styled(MuiToggleButton)(() => ({
    '&.Mui-selected, &.Mui-selected:hover': {
        color: 'white',
        backgroundColor: '#FF6F61',
    },
    '&.MuiToggleButton-root': {
        fontSize: '0.6rem',
        fontFamily: 'Pretendard-R',
        padding: 0,
        width: 50,
        borderRadius: '5px 5px 5px 5px!important',
        border: '1px solid rgba(0, 0, 0, 0.12)!important'

    }
}));

const ToggleButtonGroup2 = styled(MuiToggleButtonGroup)(() => ({
    '&.MuiToggleButtonGroup-root': {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1)
        }
    }
}));


const theme = createTheme({
    palette: {
        text: {
            primary: '#00ff00',
        },
    },
});

const SafetyFailure = ({ height }) => {
    const { project_code } = useParams();
    const defaultvalue = new Date()
    const [datevalue, setValueDate] = useState(defaultvalue);
    const handleDateChange = (event) => {
        setValueDate(event.target.value);
    };

    const [SFData, setSFData] = useState([]);

    const [type, setType] = useState("유형별");
    // 변수명 수정할 것
    const [tlist, settlist] = useState(["전체", "P3 Ph4"]);
    const [tstate, settState] = React.useState(tlist[0]);

    const [pieData, setPieData] = useState([]);
    const [barData, setBarData] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [project, setProject] = React.useState("전체");

    const handleProjectChange = (event) => {
        setProject(event.target.value)
    }

    const handleTypeChange = (event, type) => {
        if (type !== null) {
            setType(type);
        }
    };
    const handletChange = (event) => {
        settState(event.target.value);
    };

    useEffect(() => {
        const fetchData = async () => {
            const res = await axios.get(`/punchdata`);
            if (res.data.success) {
                setSFData(project === "전체"?res.data.data:res.data.data.filter(v=>v.project_projectname === project) )
                setProjectList(["전체", ...new Set(res.data.data.map(v => v.project_projectname))].filter(v=>v!==undefined));
            }
        }
        fetchData()
    }, [project])

    useEffect(() => {
        const SFTypeCounts = SFData.filter((c) => type === "유형별" ? c.PunchRiskDPK : c.PJTWBSLv2NM)
            .reduce((acc, item) => {
                const SFType = type === "유형별" ? item.PunchRiskDPK : item.PJTWBSLv2NM
                acc[SFType] = (acc[SFType] || 0) + 1;
                return acc;
            }, {})
        const pieDataArray = Object.entries(SFTypeCounts).map(([id, value]) => ({
            id,
            value,
        }));
        // Update the state with the processed data
        setPieData(pieDataArray);
    }, [SFData, type])


    useEffect(() => {
        const companyCounts = {};

        SFData.forEach(c => {
            const company = c.SCARSubconBPK;

            if (companyCounts[company]) {
                companyCounts[company]++;
            } else {
                companyCounts[company] = 1;
            }
        });

        const result = Object.entries(companyCounts).map(([company, count]) => ({
            id: company,
            value: count
        }));
        setBarData(_.sortBy(result, "value").reverse())

    }, [SFData])



    return (
        <>
            <GridLayout style={{ height: height }}
                rows={[
                    {
                        height: '100%',
                    },
                ]}
                cols={[
                    {
                        width: '40%',
                    },
                    {
                        width: '60%',
                    },
                ]}
                gap={{
                    rows: 10,
                    cols: 0,
                }}>

                <DropDownList
                    style={{
                        width: "180px", float: 'right', fontSize: '0.7rem', height: 30, fontWeight: 600, position: 'absolute', top: 8, right: 228
                    }}
                    data={projectList}
                    value={project}
                    onChange={handleProjectChange}
                />
                <div style={{ position: 'absolute', right: 16, fontSize: '0.8rem', top: 8 }}>
                    <LocalizationProvider language="ko-KR">
                        <IntlProvider locale="ko-KR">
                            <DatePicker format={"yyyy년 MM월 dd일 EEEE"} onChange={handleDateChange} value={datevalue} />
                        </IntlProvider>
                    </LocalizationProvider>
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['SingleInputDateRangeField']}>
                            <DateRangePicker
                                slots={{ field: SingleInputDateRangeField }}
                                name="allowedRange"
                                format='YYYY-MM-DD'
                                onChange={(newValue) => setDateValue(newValue)}
                            />
                        </DemoContainer>
                    </LocalizationProvider> */}
                </div>
                <GridLayoutItem row={1} col={1} style={{ position: 'relative' }}>
                    <div style={{ position: 'absolute', transform: 'translateY(-40%)', zIndex: 100 }} className='manRB' >
                        <ThemeProvider theme={theme}>
                            <ToggleButtonGroup2 size="small" onChange={handleTypeChange} value={type} exclusive aria-label="baseunit">
                                <ToggleButton2 value="유형별" aria-label='유형별' >
                                    유형별
                                </ToggleButton2>
                                <ToggleButton2 value="장소별" aria-label="장소별">
                                    장소별
                                </ToggleButton2>
                            </ToggleButtonGroup2>
                        </ThemeProvider>
                    </div>
                    {/* <ComboBox
                        className='minicombo'
                        style={{
                            width: "120px", float: 'right', fontSize: '0.6rem', height: 18,  top: -7, right: 10
                        }}
                        data={tlist}
                        value={tstate}
                        onChange={handletChange}
                    />
    */}

                    <SFPieChart pieData={pieData} />
                </GridLayoutItem>
                <GridLayoutItem row={1} col={2} style={{ position: 'relative' }}>
                    <b style={{ fontSize: "12px", marginLeft: "10px" }}>부적합 TOP 10 상위 협력사</b>
                    <SFBarChart id="safetyFailurBar" height={height - 20} data={barData.slice(0,10)} maxWidth={40} />
                </GridLayoutItem>

            </GridLayout>

        </>
    )
}

export default SafetyFailure
