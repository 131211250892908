import React, { useEffect } from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const AccidentReportLineChart = ({ height, id, lineData }) => {
    useEffect(() => {
        var root = am5.Root.new(id);
       
        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        var chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            paddingLeft: 0,
            wheelX: "panX",
            wheelY: "zoomX",
            layout: root.verticalLayout
        }));

        chart.set("cursor", am5xy.XYCursor.new(root, {}));

        var legend = chart.children.push(
            am5.Legend.new(root, {
                centerX: am5.p50,
                x: am5.p50
            })
        );

        var xRenderer = am5xy.AxisRendererX.new(root, {
            cellStartLocation: 0.1,
            cellEndLocation: 0.9,
            minorGridEnabled: true
        })

        var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "date",
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {})
        }));

        xRenderer.grid.template.setAll({
            location: 1
        })

        xAxis.data.setAll(lineData);

        var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {
                strokeOpacity: 0
            })
        }));

        yAxis.get("renderer").labels.template.setAll({
            strokeOpacity: 0,
            visible: false
        });

        xAxis.get("renderer").labels.template.setAll({
            // oversizedBehavior: "fit",
            oversizedBehavior: "wrap",
            textAlign: "center",
            fill: "#525252",
            maxWidth: 100,
            fontSize: "0.8rem",
            minScale:0.6
          });

        yAxis.get("renderer").grid.template.set("forceHidden", true);
        xAxis.get("renderer").grid.template.set("forceHidden", true);

        chart.get("colors").set("colors", [
            "#4472C4",
            "#4472C4",
            "#ED7D31",
            "#ED7D31",
            "#A5A5A5",
            "#A5A5A5",
            "#4472C4"])
        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        function makeSeries(name, fieldName) {
            var series = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: name,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: fieldName,
                categoryXField: "date"
            }));

            series.columns.template.setAll({
                tooltipText: "{name}: {valueY}",
                width: am5.percent(90),
                tooltipY: 0,
                strokeOpacity: 0
            });

            series.data.setAll(lineData);
            var series2 = chart.series.push(am5xy.LineSeries.new(root, {
                name: name,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: fieldName,
                categoryXField: "date"
            }));

            series.columns.template.setAll({
                tooltipText: "{name}: {valueY}",
                width: am5.percent(90),
                tooltipY: 0,
                strokeOpacity: 0
            });

            series2.data.setAll(lineData);

            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series.appear();

            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationY: 0,
                    sprite: am5.Label.new(root, {
                        text: "{valueY}",
                        fill: root.interfaceColors.get("alternativeText"),
                        centerY: 0,
                        centerX: am5.p50,
                        populateText: true
                    })
                });
            });

            legend.data.push(series);
            legend.markers.template.setAll({
                width: 10,
                height: 10
            });
            legend.labels.template.setAll({ fontSize: 10 });
        }

        makeSeries("A등급", "A등급");
        makeSeries("B등급", "B등급");
        makeSeries("C, D등급", "C, D등급");
      

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        chart.appear(1000, 100);

        return () => {
            root && root.dispose();
        }
    }, [lineData])
    return (
        <>
            <div id={id} style={{ height: height }}></div>
        </>
    )
}

export default AccidentReportLineChart

var data = [{
    "date": "01-01",
    "A등급": 2.5,
    "B등급": 2.5,
    "C, D등급": 2.1,
   
}, {
    "date": "2022",
    "A등급": 2.6,
    "B등급": 2.7,
    "C, D등급": 2.4,
}, {
    "date": "2023",
    "A등급": 2.8,
    "B등급": 2.9,
    "C, D등급": 2.4,
}]

