import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import ProjectOverviewPage from './pages/project/overview/overviewPage';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import '@progress/kendo-theme-default/dist/default-ocean-blue-a11y.scss';
import ProgressPage from './pages/project/progress/tProgress/progressPage';
import * as am5 from "@amcharts/amcharts5";
import DProgressPage from './pages/project/progress/dProgress/dProgressPage';
import { RecoilRoot } from 'recoil';
import VProgressPage from './pages/project/progress/vProgress/vprogressPage';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ManPowerPage from './pages/project/manpower/manPowerPage';
import CostPage from './pages/project/cost/costPage';
import MaterialPage from './pages/project/material/materialPage';
import SignIn from './login/login';
import ResetPW from './login/resetpw';
import ConfirmPW from './login/pwconfirm';
import NewPW from './login/newpw';
import SignUp from './login/signujp';
import { LicenseInfo } from '@mui/x-license-pro';
import ProtectedRoute from './common/privateRoute';
import OverviewT from './pages/shi/overviewT';
import SafetyPage from './pages/safety/safetyPage';
import QualityPage from './pages/quality/quality'
import VProgressChartO from './pages/project/progress/vProgress/vPgressTileSCurveOnly';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY);

document.title = "삼성중공업 - 대시보드";

const theme = createTheme({
  palette: {
    text: {
      // primary: '#00ff00',
    },
  },
});


am5.addLicense("AM5C363108325");
am5.addLicense("AM5M363108325");

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RecoilRoot>
    <ThemeProvider theme={theme}>
      <React.StrictMode>
        <BrowserRouter>
          <Routes>

            <Route path="/" element={<SignIn />} />
            <Route path="/dashlogin" element={<SignIn />} />
            <Route path="/dashreset-password" element={<ResetPW />} />
            <Route path="/dashpassword-confirmation" element={<ConfirmPW />} />
            <Route path="/dashnew-password" element={<NewPW />} />
            <Route path="/dashsignup" element={<SignUp />} />


            <Route path="/:project_code/overview" element={
              <ProtectedRoute>
                <ProjectOverviewPage />
              </ProtectedRoute>
            } />
            {/* <Route
              path="/:project_code"
              element={<Navigate to="overview" replace />}
            /> */}
            <Route path="/:project_code/cost" element={
              <ProtectedRoute>
                <CostPage />
              </ProtectedRoute>
            } />
            <Route path="/:project_code/progress" element={
              <ProtectedRoute>
                <ProgressPage />
              </ProtectedRoute>

            } />
            <Route path="/:project_code/progressd" element={
              <ProtectedRoute>
                <DProgressPage />
              </ProtectedRoute>
            } />
            <Route path="/:project_code/progressv" element={
              <ProtectedRoute>
                <VProgressPage />
              </ProtectedRoute>
            } />

            <Route path="/vprogress" element={
              <ProtectedRoute>
                <VProgressChartO />
              </ProtectedRoute>
            } />

            <Route path="/:project_code/mp" element={
              <ProtectedRoute>
                <ManPowerPage />
              </ProtectedRoute>
            } />
            <Route path="/:project_code/material" element={
              <ProtectedRoute>
                <MaterialPage />
              </ProtectedRoute>
            } />

            <Route path="/overview" element={
              <ProtectedRoute>
                <OverviewT />
              </ProtectedRoute>
            } />


            <Route path="/safety" element={
              <ProtectedRoute>
                <SafetyPage />
              </ProtectedRoute>

            } />

            <Route path="/quality" element={
              <ProtectedRoute>
                <QualityPage />
              </ProtectedRoute>

            } />

          </Routes>
        </BrowserRouter>
      </React.StrictMode>
    </ThemeProvider>
  </RecoilRoot>,
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
