import { atom } from 'recoil';

export const progressState = atom({
  key: 'progressState', // unique ID
  default: null, // default value
});

export const actualPercentState = atom({
  key: 'actualPercentState', // unique ID
  default: null, // default value
});


export const planPercentState = atom({
  key: 'planPercentState', // unique ID
  default: null, // default value
});