import React, { useEffect, useState } from 'react'
import _ from 'lodash';
import {
    GridLayout,
    GridLayoutItem,
} from "@progress/kendo-react-layout";
import axios from "../../../common/axios";
import dayjs from "dayjs"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import LtiLineChart from './ltiChart';
const filePath = process.env.REACT_APP_FILEPATH;

const LTIChart = ({ height }) => {

    const [sourceData, setSourceData] = useState([]);

    // const [yearList, setYearList] = useState([]);
    const [selectedYear, setSelectedYear] = useState(dayjs().format("YYYY"));
    const yearList = sourceData.map(v=>v.year);
    const yearList2 = sourceData.map(v=>v.year);

    const [selectedYear2, setSelectedYear2] = useState(dayjs().format("YYYY"));

    const projectData = sourceData.find(v => v.year === selectedYear);
    const projectList = projectData ? projectData.data.map(v=>v.project) : [];


    const [selectedProject, setSelectedProject] = useState("종합");

    const chartData1 = sourceData.find(v=>v.year === selectedYear)?.data.filter(v=>v.project === selectedProject);
    const chartData2 = projectData? projectData.data.filter(v=>v.project !== "종합"):[];


    const [isEditing, setIsEditing] = useState(false);
    const [isEditing2, setIsEditing2] = useState(false);


    const handleChangeYear = (event) => {
        setSelectedYear(event.target.value);
        setIsEditing(false);
    };
    const handleChangeProject = (event) => {
        setSelectedProject(event.target.value);
        setIsEditing(false);
    };


    const handleChangeYear2 = (event) => {
        setSelectedYear2(event.target.value);
        setIsEditing2(false);
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`/getJSON?path=${filePath}/files/lti/accumlateltir.json`)
                
                if (res.data.length > 0) {

                    // const yearLists = res.data.map(v=>v.year);
                    setSourceData(res.data)
                    // setYearList(yearLists)
                    // setYearList2(yearLists)
                    // setProjectList(res.data.find(v=>v.year ===selectedYear).data.map(vf=>vf.project))

                    // setChartData1(res.data.find(v=>v.year ===selectedYear).data.filter(vf=>vf.project === selectedProject))


                }
            } catch (error) {
                console.error('데이터 가져오기 중 오류 발생:', error);
            }
        };
        fetchData();
    }, []);



    const handleClick = () => {
        setIsEditing(true);
    };

    const handleClick2 = () => {
        setIsEditing2(true);
    };

    const series1 = [
        {name:"LTIF", filed:"LTIF_actual_accumlated" },
        {name:"TRCF", filed:"TRCF_actual_accumlated" }
    ]

    const series2 = [...chartData2 && chartData2.map((v)=>({
        name:v.project+" LTIF",
        filed:"LTIF_actual_month"
    })), 
    ...chartData2 && chartData2.map((v)=>({
        name:v.project+" TRCF",
        filed:"LTIF_actual_month"
    })), 
]

    return (
        <>
            <GridLayout style={{ height: height }}
                rows={[
                    {
                        height: '50%',
                        height: '50%',
                    },
                ]}
                cols={[
                    {
                        width: '100%',
                    },

                ]}
                gap={{ rows: 0, cols: 0, }}>

                <GridLayoutItem row={1} col={1} style={{ position: 'relative' }}>
                <div
                        style={{
                            position: "absolute",
                            display: "flex",
                            alignItems: "center",
                            top: -10,
                            left: "50%", // Center horizontally
                            transform: "translateX(-50%)", // Adjust based on its own width
                            textAlign: "left",
                            fontSize: 11,
                            zIndex:99999
                        }}
                    >

                        {isEditing ? (
                            <>
                                <FormControl size='small' sx={{ m: 1, minWidth: 120, fontSize: 13, zIndex:99999}}>
                                    <InputLabel id="demo-simple-select-standard-label"></InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={selectedYear}
                                        onChange={handleChangeYear}
                                        label=""
                                        className='selcetSafety'
                                    >
                                        {yearList.map((v, index) => (
                                            <MenuItem key={index} value={v} >{v}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl size='small' sx={{ m: 1, minWidth: 120, fontSize: 11, }}>
                                    <InputLabel id="demo-simple-select-standard-label"></InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={selectedProject}
                                        onChange={handleChangeProject}
                                        className='selcetSafety'
                                        label=""
                                    >
                                        {projectList.map((v, index) => (
                                            <MenuItem key={index} value={v}>{v}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </>
                        ) :
                            (
                                <span
                                    style={{ cursor: "pointer" }}
                                    onClick={handleClick}
                                >
                                    {selectedYear} {selectedProject} LTIF/TRCF 누계 지표
                                </span>
                            )}


                    </div>

                    <LtiLineChart chartID={"accumltir"} series={series1} chartData={chartData1 === undefined ? []:chartData1} height={height/2} />



                </GridLayoutItem>
                <GridLayoutItem row={2} col={1} style={{ position: 'relative' }}>
                <LtiLineChart chartID={"monthltir"} series={series2} chartData={chartData2 === undefined ? []:chartData2} height={height/2} />

                    <div
                        style={{
                            position: "absolute",
                            display: "flex",
                            alignItems: "center",
                            top: -10,
                            left: "50%", // Center horizontally
                            transform: "translateX(-50%)", // Adjust based on its own width
                            textAlign: "left",
                            fontSize: 11,
                        }}
                    >

                        {isEditing2 ? (
                            <>
                                <FormControl size='small' sx={{ m: 1, minWidth: 120, fontSize: 13, }}>
                                    <InputLabel id="demo-simple-select-standard-label"></InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={selectedYear2}
                                        onChange={handleChangeYear2}
                                        label=""
                                        className='selcetSafety'
                                    >
                                        {yearList2.map((v, index) => (
                                            <MenuItem key={index} value={v}>{v}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>


                            </>
                        ) :
                            (
                                <span
                                    style={{ cursor: "pointer" }}
                                    onClick={handleClick2}
                                >
                                    {selectedYear2} (프로젝트별) LTIF/TRCF 월간 지표
                                </span>
                            )}


                    </div>





                </GridLayoutItem>
            </GridLayout>

        </>
    )
}

export default LTIChart
