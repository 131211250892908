import React, { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { QualityInspectionStatusData } from "../../../../atom/qualityState";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import dayjs from "dayjs";
import SupervisionTable from "./SupervisionTable";
import ConsTable from "./ConsTable";
import InspectionPieChart from "./InspectionPieChart";
import InspectionNestedPieChart from "./InspectionNestedPieChart";
import "./inspectionStatus.css";

const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

const InspectionStatus = ({
  height,
  targetProject,
  targetVendor,
  targetDisc,
  dateValue,
}) => {
  const data = useRecoilValue(QualityInspectionStatusData);

  const filter_data = useMemo(() => {
    let copy_data = [...data];

    const [startDate, endDate] = dateValue;

    if (targetProject.value !== "all") {
      copy_data = [...copy_data].filter(
        (c) => c.project_projectnumber === targetProject.value
      );
    }

    if (targetDisc !== "전체") {
      copy_data = [...copy_data].filter((c) => c.ITPDiscipline === targetDisc);
    }

    if (targetVendor !== null && targetVendor?.label) {
      copy_data = [...copy_data].filter(
        (c) => c.uveVendorNameTB50 === targetVendor.label
      );
    }

    if (startDate && endDate) {
      copy_data = [...copy_data].filter((c) => {
        const { QIDateTime } = c;

        const QIDateTime_dayjs = dayjs(QIDateTime, "MM-DD-YYYY");

        return startDate <= QIDateTime_dayjs && QIDateTime_dayjs <= endDate;
      });
    }

    return copy_data;
  }, [targetProject, targetVendor, targetDisc, dateValue, data]);

  const inspection_status_data = useMemo(() => {
    let pieChartData = [...initial_pie_chart_data];
    let nestedPieChartData = [];
    let nestedPieChartData2 = [];

    let constructionTableData = { ...initial_construction_table_data };
    let supervisionTableData = { ...initial_supervision_table_data };

    const construction_request = [];
    const construction_canceled = [];
    const construction_exception = [];
    const construction_actual = [];
    const construction_first_pass = [];
    const construction_retry_first_pass = [];
    const construction_retry_second_pass = [];
    const construction_retry_third_pass = [];
    const construction_failed = [];
    const construction_untaken_action = [];
    const construction_pass_total = [];

    const supervision_request = [];
    const supervision_canceled = [];
    const supervision_exception = [];
    const supervision_failed = [];
    const supervision_approval = [];

    filter_data.forEach((c) => {
      const { PreviousAction, InspSHINo, InspRecordStatus, SHIInspResult } = c;

      if (
        PreviousAction === "협력사 검사요청서 검토 요청" ||
        PreviousAction === "시공사 검사요청서 재검토 요청"
      ) {
        construction_request.push(c);
      }

      if (PreviousAction === "수정요청 (협력사 취소함)") {
        construction_canceled.push(c);
      }

      if (PreviousAction === "수정요청 (예외)") {
        construction_exception.push(c);
      }

      if (PreviousAction === "시공사 검사결과 입력 요청") {
        construction_actual.push(c);
      }

      if (InspSHINo === 1 && PreviousAction === "시공사 합격") {
        construction_first_pass.push(c);
      }

      if (InspSHINo === 2 && PreviousAction === "시공사 합격") {
        construction_retry_first_pass.push(c);
      }

      if (InspSHINo === 3 && PreviousAction === "시공사 합격") {
        construction_retry_second_pass.push(c);
      }

      if (InspSHINo > 3 && PreviousAction === "시공사 합격") {
        construction_retry_third_pass.push(c);
      }

      if (PreviousAction === "시공사 합격") {
        construction_pass_total.push(c);
      }

      if (PreviousAction === "시공사 불합격 통지") {
        construction_failed.push(c);
      }

      if (PreviousAction === "감리 검사취소") {
        construction_untaken_action.push(c);
      }

      if (PreviousAction === "감리단 결과 입력 요청") {
        supervision_request.push(c);
      }

      if (
        PreviousAction === "감리 검사취소" ||
        PreviousAction === "시공사 지적 미조치 (검사취소)"
      ) {
        supervision_canceled.push(c);
      }

      if (PreviousAction === "예외 (검사취소)") {
        supervision_exception.push(c);
      }

      if (PreviousAction === "감리단 불합격 통지") {
        supervision_failed.push(c);
      }

      if (PreviousAction === "합격") {
        supervision_approval.push(c);
      }
    });

    const construction_request_actaul_percent = returnZeroIfInvalid(
      (construction_actual.length / construction_request.length) * 100
    );

    const construction_first_pass_percent = returnZeroIfInvalid(
      (construction_first_pass.length / construction_pass_total.length) * 100
    );

    const construction_retry_first_pass_percent = returnZeroIfInvalid(
      (construction_retry_first_pass.length / construction_pass_total.length) *
        100
    );

    const construction_retry_second_pass_percent = returnZeroIfInvalid(
      (construction_retry_second_pass.length / construction_pass_total.length) *
        100
    );

    const construction_retry_third_pass_percent = returnZeroIfInvalid(
      (construction_retry_third_pass.length / construction_pass_total.length) *
        100
    );

    const construction_pass_percent = returnZeroIfInvalid(
      (construction_pass_total.length / construction_actual.length) * 100
    );

    // filter_data.forEach((c) => {
    //   const { InspRecordStatus, PreviousAction } = c;

    //   if (InspRecordStatus === "Approved_SHI") {
    //     supervision_request.push(c);
    //   }

    //   if (PreviousAction === "시공사 지적 미조치 (검사취소)") {
    //     supervision_canceled.push(c);
    //   }

    //   if (PreviousAction === "예외 (검사취소)") {
    //     supervision_exception.push(c);
    //   }

    //   if (PreviousAction === "감리단 불합격 통지") {
    //     supervision_failed.push(c);
    //   }
    // });

    const supervision_actual =
      supervision_approval.length + supervision_failed.length;

    const supervision_request_actaul_percent = returnZeroIfInvalid(
      (supervision_actual / supervision_request.length) * 100
    );

    const supervision_first_pass_percent =
      supervision_actual === 0
        ? 0
        : (1 -
            returnZeroIfInvalid(
              supervision_failed.length / supervision_actual
            )) *
          100;

    pieChartData = [
      {
        category: "수행",
        title: "수행",
        value: construction_actual.length,
        color: "#48CAE4",
        subData: [
          {
            category: "One-Pass(1차 합격)",
            value: construction_first_pass.length,
            color: "#00AF50",
          },
          {
            category: "1차 재검사 후 합격",
            value: construction_retry_first_pass.length,
            color: "#91CF50",
          },
          {
            category: "2차 재검사 후 합격",
            value: construction_retry_second_pass.length,
            color: "#FFE699",
          },
          {
            category: "3차 이상 재검사 후 합격",
            value: construction_retry_third_pass.length,
            color: "#FEC000",
          },
          {
            category: "불합격",
            value: construction_failed.length,
            color: "#D1D1D1",
          },
        ],
      },
      {
        category: "취소",
        title: "취소",
        color: "#D1D1D1",
        value: construction_canceled.length,
        subData: [],
      },
    ];

    nestedPieChartData = [
      {
        category: "수행",
        title: "수행",
        value: construction_actual.length,
      },
      {
        category: "취소",
        title: "취소",
        value: construction_canceled.length,
      },
    ];

    nestedPieChartData2 = [
      {
        category: "One-Pass(1차 합격)",
        value: construction_first_pass.length,
        color: "#00AF50",
      },
      {
        category: "1차 재검사 후 합격",
        value: construction_retry_first_pass.length,
        color: "#91CF50",
      },
      {
        category: "2차 재검사 후 합격",
        value: construction_retry_second_pass.length,
        color: "#FFE699",
      },
      {
        category: "3차 이상 재검사 후 합격",
        value: construction_retry_third_pass.length,
        color: "#FEC000",
      },
      {
        category: "불합격",
        value: construction_failed.length,
        color: "#D1D1D1",
      },
    ];

    constructionTableData = {
      ...constructionTableData,
      request: construction_request.length,
      canceled: construction_canceled.length,
      exception: construction_exception.length,
      actual: construction_actual.length,
      request_actaul_percent: construction_request_actaul_percent,
      first_pass: construction_first_pass.length,
      retry_first_pass: construction_retry_first_pass.length,
      retry_second_pass: construction_retry_second_pass.length,
      retry_third_pass: construction_retry_third_pass.length,
      failed: construction_failed.length,
      first_pass_percent: construction_first_pass_percent,
      retry_first_pass_percent: construction_retry_first_pass_percent,
      retry_second_pass_percent: construction_retry_second_pass_percent,
      retry_third_pass_percent: construction_retry_third_pass_percent,
      pass_percent: construction_pass_percent,
      untaken_action: construction_untaken_action.length,
    };

    supervisionTableData = {
      ...supervisionTableData,
      request: supervision_request.length,
      canceled: supervision_canceled.length,
      exception: supervision_exception.length,
      actual: supervision_actual,
      request_actaul_percent: supervision_request_actaul_percent,
      failed: supervision_failed.length,
      first_pass_percent: supervision_first_pass_percent,
    };

    return {
      pieChartData,
      constructionTableData,
      supervisionTableData,
      nestedPieChartData,
      nestedPieChartData2,
    };
  }, [filter_data]);

  return (
    <GridLayout
      style={{ height: height }}
      className="quality-detail-inspection-status"
      rows={[
        {
          height: height * 0.6 - 5,
        },
        {
          height: height * 0.4 - 5,
        },
      ]}
      cols={[
        {
          width: "calc(50% - 5px)",
        },
        {
          width: "calc(50% - 5px)",
        },
      ]}
      gap={{
        rows: 10,
        cols: 10,
      }}
    >
      <GridLayoutItem row={1} rowSpan={2} col={1}>
        <p className="quality-detail-inspection-status-title">
          시공사 검측 실적 요약
        </p>

        <InspectionNestedPieChart
          height={height - 23}
          data={inspection_status_data.nestedPieChartData}
          detail_data={inspection_status_data.nestedPieChartData2}
        />

        {/* <InspectionPieChart
          height={height - 23}
          data={inspection_status_data.pieChartData}
          set_sub_category_data={set_sub_category_data}
        /> */}
      </GridLayoutItem>
      <GridLayoutItem row={1} col={2}>
        <p className="quality-detail-inspection-status-title">
          시공사 검측 실적 상세
        </p>
        <ConsTable
          height={height * 0.6 - 5 - 23}
          data={inspection_status_data.constructionTableData}
        />
      </GridLayoutItem>
      <GridLayoutItem row={2} col={2}>
        <p className="quality-detail-inspection-status-title">
          감리 검측 실적 상세
        </p>
        <SupervisionTable
          height={height * 0.4 - 5 - 23}
          data={inspection_status_data.supervisionTableData}
        />
      </GridLayoutItem>
    </GridLayout>
  );
};

export default InspectionStatus;

const initial_pie_chart_data = [
  {
    category: "수행",
    title: "수행",
    value: 0,
    color: "#9DC3E6",
    subData: [
      { category: "One-Pass(1차 합격)", value: 0, color: "#1F3864" },
      { category: "1차 재검사 후 합격", value: 0, color: "#FFBF00" },
      { category: "2차 재검사 후 합격", value: 0, color: "#ED7D32" },
      { category: "3차 이상 재검사 후 합격", value: 0, color: "#843C0B" },
      { category: "불합격", value: 0, color: "#FF0000" },
    ],
  },
  {
    category: "취소",
    title: "취소",
    color: "#9DC3E6",
    value: 0,
    subData: [],
  },
];

const set_sub_category_data = [
  { category: "One-Pass(1차 합격)", value: 0, color: "#1F3864" },
  { category: "1차 재검사 후 합격", value: 0, color: "#1F3864" },
  { category: "2차 재검사 후 합격", value: 0, color: "#1F3864" },
  { category: "3차 이상 재검사 후 합격", value: 0, color: "#1F3864" },
  { category: "불합격", value: 0, color: "#1F3864" },
];

const initial_construction_table_data = {
  request: 0,
  canceled: 0,
  exception: 0,
  actual: 0,
  request_actaul_percent: 0,
  first_pass: 0,
  retry_first_pass: 0,
  retry_second_pass: 0,
  retry_third_pass: 0,
  failed: 0,
  first_pass_percent: 0,
  retry_first_pass_percent: 0,
  retry_second_pass_percent: 0,
  retry_third_pass_percent: 0,
  pass_percent: 0,
  untaken_action: 0,
};

const initial_supervision_table_data = {
  request: 0,
  canceled: 0,
  exception: 0,
  actual: 0,
  request_actaul_percent: 0,
  failed: 0,
  first_pass_percent: 0,
};

const returnZeroIfInvalid = (value) => {
  if (value === Infinity || isNaN(value) || value === false) {
    return 0;
  }
  return value;
};
