import React from 'react'
import { ResponsivePie } from '@nivo/pie'

const AccidentReportPieChart = ({pieData}) => {
    const data = [
        {
          "id": "A등급",
          "value": 457,
        },
        {
          "id": "B등급",
          "value": 179,
        },
        {
          "id": "C, D등급",
          "value": 67,
        },
      ]

  return (
    <ResponsivePie
        data={pieData}
        colors={["#4472C4", "#A5A5A5", "#ED7D31"]}
        margin={{ top: 30, right: 30, bottom: 60, left: 30 }}
        // startAngle={-90}
        // padAngle={0.7}
        innerRadius={0.6}
        cornerRadius={3}
        activeOuterRadiusOffset={4}
        borderWidth={0}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    0.2
                ]
            ]
        }}
        // arcLinkLabel={false}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    2
                ]
            ]
        }}
        valueFormat={value => (value === 0 ? null : value)}
        // innerRadius={data.map(entry => entry.id === '불합격' ? 0.6 : 0)} 
        // legends={[
        //     {
        //         anchor: 'bottom-left',
        //         direction: 'column',
        //         justify: false,
        //         translateX: -50,
        //         translateY: 50,
        //         itemWidth: 40,
        //         itemHeight: 20,
        //         itemsSpacing: 0,
        //         symbolSize: 8,
        //         itemDirection: 'left-to-right'
        //     }
        // ]}
    />
  )
}

export default AccidentReportPieChart
