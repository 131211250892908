import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import axios from "../../../../common/axios";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
  ChartTitle,
} from "@progress/kendo-react-charts";
import "hammerjs";
import { ComboBox } from "@progress/kendo-react-dropdowns";


const VBulletPage = ({ height, width }) => {
  const [vendordata, setvendordata] = useState([]);
  const [dlist, setdlist] = useState([]);
  const { project_code } = useParams();
  const [state, setState] = React.useState(dlist[0]);
  const handleChange = (event) => {
    setState(event.target.value);
  };



  const [iconstate, seticonstate] = useState([]);


  useEffect(() => {
    const fetchData = async () => {

      let body =
      {
        "bpname": "업체 등록",
        "lineitem": "no",
        "filter_criteria": {
          "join": "AND",
          "filter": [
            {
              "field": "status",
              "value": 'Active',
              "condition_type": "eq"
            },

          ]
        }
      }
      const res = await axios.post(`/getbprecordsdash?path=${project_code}`, body);
      if (res.data.success) {
        const vendors = res.data.data.data.map((v, index) => ({ 공종: v.DisciplineSPD, companyName: v.uveVendorNameTB50, id: index }))
        seticonstate(_.uniqBy(vendors, 'companyName').map((v) => ({ id: v.id, state: true })))
        setvendordata(vendors)
        setdlist(_.uniqBy(vendors, '공종').map(v => v['공종']))
      }

    }
    fetchData()
  }, [project_code])

  const [data2, setdata2] = useState([]);

  useEffect(() => {
    const fetchData = async () => {

      const res = await axios.get(`/getvendorprogress?project=${project_code}`)
      setdata2(res.data.data)

    };

    fetchData();

  }, [project_code]);


  const progressby =
    data2.map((v) => ({ ...v, 공종: vendordata && vendordata.find(vf => vf.companyName === v.companyName) && vendordata.find(vf => vf.companyName === v.companyName)['공종'] }))

  const lastvendor = state ? progressby.filter(v => v['공종'] === state) : progressby



  useEffect(() => {
    seticonstate(lastvendor && lastvendor.map((v, index) => ({ id: index, state: true })))
  }, [project_code])

  const handle2 = (index) => {
    seticonstate(prevState =>
      prevState.map(item =>
        item.id === index ? { ...item, state: !item.state } : item
      )
    );
  };

  const colmunNo = Math.round(lastvendor.length / 2);

  const chartwidth =
    (width / colmunNo / 2) * 0.95 > 200 ? 200 : (width / colmunNo / 2) * 0.95;

  const columnList = [];

  for (var i = 1; i <= colmunNo * 2; i++) {
    columnList.push({ width: `${100 / colmunNo / 2}%` });
  }

  const hidden = {
    visible: false,
  };

  const SharedTooltip = (props) => {
    const { points } = props;
    return (
      <div style={{ fontSize: "0.6rem" }}>
        <div>계획: {points[0].value.target.toFixed(2)}%</div>
        <div>실적: {points[0].value.current.toFixed(2)}%</div>
        <div>
          차이: {(points[0].value.current - points[0].value.target).toFixed(2)}%
        </div>
      </div>
    );
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;



  return (
    <>
      <GridLayout
        style={{
          height: 2 * (height / 6.5) - 65, width: (width - 48 - 14 * 16),

        }}
        rows={[
          {
            height: "0%",
          },
          {
            height: "0%",
          },
          {
            height: "15%",
          },
          {
            height: "35%",
          },
          {
            height: "15%",
          },
          {
            height: "35%",
          },
        ]}
        cols={columnList}
        gap={{
          rows: 0,
          cols: 0,
        }}
      >
        <div style={{
          width: "100px", float: 'right', fontSize: '0.7rem', height: 30, fontWeight: 600, position: 'absolute', top: -12, left: 120
        }}>
          <ComboBox

            data={dlist}
            value={state}
            label="공종"
            onChange={handleChange}
          />

        </div>




        {lastvendor.map((v, index) => (
          <GridLayoutItem
            key={index}
            col={
              index + 1 > columnList.length * 0.5
                ? index * 2 + 1 - columnList.length
                : index * 2 + 1
            }
            row={index + 1 > columnList.length * 0.5 ? 5 : 3}
            colSpan={2}
            rowSpan={2}
            style={{
              position: 'relative',  // Add this to make it a positioning context
              height: '100%',        // Ensure it takes full height
              textAlign: 'center'
            }}
          >

            {/* <button style={{border:'none',position:'relative', zIndex:999, top:'20%', right:'-35%', cursor:'pointer', background: 'none', 
            color: 
            iconstate&&iconstate[index] && iconstate[index]['state'] ? 
            '#34568B'
            : '#FA7A35'
          }} 
              onClick={handle2}
              value={index}
            > <MdOpenInNew /></button> */}

            <div
              onClick={() => handle2(index)}
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                right: '0',
                fontSize: '12px',
                fontWeight: 'bold',
                color: '#000',
                padding: '20px',
                cursor: 'pointer',

              }}
            >
              {/* <span style={{ zIndex: 1000,}}> */}
              {v.companyName}
              {/* </span> */}
            </div>

            <div style={{
              position: 'absolute',
              top: '40px',  // Adjust based on the height of the company name div
              left: '0',
              right: '0',
              bottom: '0',
              overflowY: 'auto',  // In case content overflows
              zIndex: 999999
            }}>
              {iconstate[index] && iconstate[index]['state'] ?
                <Chart
                  style={{
                    height: (height - 410) * 5 / 12 / 2, marginTop: -20
                  }}
                >
                  <ChartTooltip shared={true} render={sharedTooltipRender} />

                  <ChartTitle
                    text={`&nbsp;\n${(v.Actual - v.Plan).toFixed(
                      2
                    )}% \n (계획:${v.Plan.toFixed(2)}% 실적:${v.Actual.toFixed(
                      2
                    )}%)\n\n`}
                    font="0.7rem pretendard-B"
                  />
                  <ChartSeries>
                    <ChartSeriesItem
                      type="bullet"
                      color={v.Actual - v.Plan < 0 ? "#CD212A" : "#00A170"}
                      data={[v.Actual, v.Plan]}
                    />
                  </ChartSeries>
                  <ChartValueAxis>
                    <ChartValueAxisItem
                      majorGridLines={hidden}
                      minorTicks={hidden}
                      min={0}
                      max={100}
                      labels={{ font: "0.7rem Arial, KoPub" }}
                    />
                  </ChartValueAxis>
                </Chart>

                :

                <>
                  <br />

                  <div style={{ font: '0.7rem pretendard-B' }}>현황별 액티비티 개수</div>
                  <div style={{ font: '0.7rem pretendard-B' }}>
                    <span>전체: {v.total}</span>&nbsp;&nbsp;
                    <span>선행: {v.ahead}</span>&nbsp;&nbsp;
                    <span>지연: {v.behind}</span>
                  </div>
                </>

              }
            </div>
          </GridLayoutItem>
        ))}


      </GridLayout>
    </>
  );
};

export default VBulletPage;
