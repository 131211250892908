import React, { useEffect, useLayoutEffect } from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const LtiLineChart = ({  chartID,height, chartData,series }) => {

    console.log(height)
    console.log(chartData)

    const monthList = [{ month: 1 }, { month: 2  }, { month: 3 }, { month: 4 }, { month: 5 }, { month: 6 }, { month: 7 }, { month: 8 }, { month: 9 }, { month: 10 }, { month: 11 }, { month: 12 },]

    const newChartData = monthList.map((vf) => ({ ...vf, ...chartData.find(v => v.month === vf.month) }))

    console.log(newChartData)

    useLayoutEffect(() => {

        var root = am5.Root.new(chartID);

        root.setThemes([
            am5themes_Animated.new(root)
        ]);


        var chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: "none",
                wheelY: "none"
            })
        );

        var xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(root,

                {
                    min: 1,
                    max: 12, strictMinMax: true, numberFormat: "#'월",
                    renderer: am5xy.AxisRendererX.new(root, {
                        minGridDistance: 50
                    }),
                    tooltip: am5.Tooltip.new(root, {})
                })
        );

        xAxis.get("renderer").labels.template.setAll({
            fontSize:12,
            textAlign: "center",
       
          });

        var yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                min: 0,
                strictMinMax: true, 
                renderer: am5xy.AxisRendererY.new(root, {})
            })
        );

        var legend = chart.children.push(am5.Legend.new(root, {
            // centerY: am5.percent(50),
            y: am5.percent(90),
            // x:am5.percent(90),
            // layout: root.verticalLayout,
            height: am5.percent(100),
            verticalScrollbar: am5.Scrollbar.new(root, {
              orientation: "vertical"
            })
          }));


        function makeSeries(name, fieldName) {
            var series = chart.series.push(am5xy.LineSeries.new(root, {
                name: name,
                minBulletDistance: 10,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: fieldName,
                valueXField: "month",
                tooltip: am5.Tooltip.new(root, {}),
                legendLabelText: "[bold {stroke}]{name}:[/]",
                legendValueText: "[bold {stroke}]{valueY}[/]",

            }));



            // series.columns.template.setAll({
            //   tooltipText: "{name}, {valueXF}: {valueY}",
            //   tooltipY: am5.percent(10),
            // });

            series.strokes.template.setAll({ strokeWidth: 1 });

            series.data.setAll(newChartData);
            series.get("tooltip").label.set("text", "[bold]{name}[/]: {valueY}")

            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series.appear();

            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    sprite: am5.Label.new(root, {
                        text: "{valueY}",
                        fill: root.interfaceColors.get("alternativeText"),
                        centerY: am5.p50,
                        centerX: am5.p50,
                        populateText: true
                    })
                });
            });
            series.bullets.push(function () {
              return am5.Bullet.new(root, {
                sprite: am5.Circle.new(root, {
                  radius: 3,
                  fill: series.get("fill"),
                  stroke: root.interfaceColors.get("background"),
                  strokeWidth: 2
                })
              });
            });
            legend.data.push(series);
            // legend.markers.template.setAll({
            //     width: 10,
            //     height: 10
            // });
            legend.labels.template.setAll({ fontSize: 10 });
            // legend.valueLabels.template.set("forceHidden", true);
        }

        // var legend = chart.children.push(am5.Legend.new(root, {}));
        // legend.data.push(newChartData);
        // legend.data.setAll(chart.series.values);
        // legend.labels.template.setAll({ fontSize: 10 });
        // legend.valueLabels.template.set("forceHidden", true);




        if (newChartData.length > 0) {
          series.forEach(element => {
            makeSeries(element.name, element.filed);
          });
     
            // makeSeries('TRCF', 'TRCF_actual_accumlated');
        }

        // Add cursor
        // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
        var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            xAxis: xAxis
        }));
   // Add cursor


        chart.appear(1000, 100);

        // Create axis ranges
function createRange(value, endValue, color, text) {
    var rangeDataItem = yAxis.makeDataItem({
      value: value,
      endValue: endValue
    });
    
    var range = yAxis.createAxisRange(rangeDataItem);
    
    if (endValue) {
      range.get("axisFill").setAll({
        fill: color,
        fillOpacity: 0.2,
        visible: true
      });
      
      range.get("label").setAll({
        fill: am5.color(0xffffff),
        text: text,
        location: 1,
        background: am5.RoundedRectangle.new(root, {
          fill: color
        })
      });
    }
    else {
      range.get("label").setAll({
        fill: am5.color(0xffffff),
        text: text,
        fontSize:11,
        background: am5.RoundedRectangle.new(root, {
          fill: color
        })
      });
    }
  
    range.get("grid").setAll({
      stroke: color,
      strokeOpacity: 1,
      location: 1
    });
    
  }
if(newChartData.length>0){
  createRange(newChartData[0].ltir_plan, undefined, am5.color(0xff621f), "목표 LTIF");
  // createRange(1, undefined, am5.color(0xff621f), "목표 LTIF");
  createRange(newChartData[0].trcf_plan, undefined, am5.color(0x297373), "목표 TRCF");
}

        return () => {
            root && root.dispose();
        }
    }, [newChartData])

    return (
        <>
            <div id={chartID} style={{ height: height-15, }}></div>
        </>
    )
}

export default LtiLineChart
