import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import axios from "../../../common/axios"
import {
  Card, CardTitle, CardBody, CardImage,
  GridLayout,
  GridLayoutItem,
} from "@progress/kendo-react-layout";
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import dayjs from 'dayjs';
import PieChart from './overviewPieChart';

const ToggleButton2 = styled(MuiToggleButton)(() => ({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: '#FF6F61',
  },
  '&.MuiToggleButton-root': {
    fontSize: '0.6rem',
    fontFamily: 'Pretendard-R',
    padding: 0,
    width: 60,
    borderRadius: '5px 5px 5px 5px!important',
    border: '1px solid rgba(0, 0, 0, 0.12)!important'

  }
}));

const ToggleButtonGroup2 = styled(MuiToggleButtonGroup)(() => ({
  '&.MuiToggleButtonGroup-root': {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1)
    }
  }
}));


const theme = createTheme({
  palette: {
    text: {
      primary: '#00ff00',
    },
  },
});

const Man = ({ height }) => {

  const location = useLocation();
  const { project_code } = useParams();
  const pathname = location.pathname.split('/').pop();

  const [selectedValue2, setSelectedValue2] = React.useState("직종");

  const [mandata, setManData] = useState([])
  const [dateValue1, setDateValue1] = useState(new Date(dayjs().add(-1, 'days')));

  // const [manPieData, setManPieData] = useState([])


  const handleChange2 = (event, selectedValue2) => {
    if (selectedValue2 !== null) {
      setSelectedValue2(selectedValue2);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {

        const res = await axios.get(`/getmanData?project=${project_code}&selectedValue2=${selectedValue2}`)
        setManData(res.data.data);

      } catch (userError) {
        console.error("Error fetching user data:", userError);
        // Handle error in fetching user data
      }
    }
    fetchData()

  }, [selectedValue2, project_code]);


  const manPieData = (mandata.map(item => {
    // 현재 항목에서 "date" 값을 가져옴
    const date = item.date;

    // "date"와 "none"을 제외한 키와 값을 추출하여 배열에 추가
    const newData = Object.keys(item)
      .filter(key => key !== "date" && key !== "none" && item[key] > 0)
      .map(key => ({ date, id: key, value: item[key] }));

    return newData;
  })).flat();

  const totalSum1 = _.sumBy(manPieData, "value")



  return (
    <>
      <GridLayout style={{ height: height }}
        rows={[
          {
            height: '100%',
          },
        ]}
        cols={[
          {
            width: '100%',
          }
        ]}
        gap={{
          rows: 10,
          cols: 0,
        }}>

        <GridLayoutItem row={1} col={1} style={{ position: 'relative' }}>
          {pathname === "overview" ? (
            <>
              <div style={{ position: 'absolute', transform: 'translateY(-50%)', zIndex: 100 }} className='manRB' >
                <ThemeProvider theme={theme}>
                  <ToggleButtonGroup2 size="small" onChange={handleChange2} value={selectedValue2} exclusive aria-label="baseunit">
                    <ToggleButton2 value="직종" aria-label='직종' >
                      직종
                    </ToggleButton2>
                    <ToggleButton2 value="업체별" aria-label="업체별">
                      업체별
                    </ToggleButton2>
                  </ToggleButtonGroup2>
                </ThemeProvider>
              </div>
            </>) : (
            <>
              <div style={{ position: 'absolute', transform: 'translateY(-50%)', zIndex: 100 }} className='manRB'>
                <ThemeProvider theme={theme}>
                  <ToggleButtonGroup2 size="small" onChange={handleChange2} value={selectedValue2} exclusive aria-label="baseunit">
                    <ToggleButton2 value="직종" aria-label='직종' >
                      직종
                    </ToggleButton2>
                    <ToggleButton2 value="업체별" aria-label="업체별">
                      업체별
                    </ToggleButton2>
                  </ToggleButtonGroup2>
                </ThemeProvider>
              </div>
            </>
          )}
          <PieChart data={manPieData} text="명" totalSum={totalSum1} dateValue={dateValue1} setDateValue={setDateValue1} />
        </GridLayoutItem>

      </GridLayout>

    </>
  );

}

export default Man
