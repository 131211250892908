import React, { useEffect, useState } from 'react'
import _ from 'lodash';
import {
    GridLayout,
    GridLayoutItem,
} from "@progress/kendo-react-layout";
import SFPieChart from '../sfPieChart';
import { useParams } from 'react-router-dom';
import axios from "../../../common/axios"
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import DisasterStackChart from './disasterStackChart';
import SFBarChart from '../sfBarChart';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import 'dayjs/locale/ko';
import dayjs from "dayjs";


const ToggleButton2 = styled(MuiToggleButton)(() => ({
    '&.Mui-selected, &.Mui-selected:hover': {
        color: 'white',
        backgroundColor: '#FF6F61',
    },
    '&.MuiToggleButton-root': {
        fontSize: '0.6rem',
        fontFamily: 'Pretendard-R',
        padding: 0,
        width: 50,
        borderRadius: '5px 5px 5px 5px!important',
        border: '1px solid rgba(0, 0, 0, 0.12)!important'

    }
}));

const ToggleButtonGroup2 = styled(MuiToggleButtonGroup)(() => ({
    '&.MuiToggleButtonGroup-root': {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1)
        }
    }
}));


const theme = createTheme({
    palette: {
        text: {
            primary: '#00ff00',
        },
    },
});

const DisasterStatus = ({ height }) => {
    const { project_code } = useParams();
    const [IRData, setIRData] = useState([]);
    const [pieData, setPieData] = useState([]);
    const [barData, setBarData] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [project, setProject] = React.useState("전체");
    const [value, setValue] = useState([dayjs().subtract(1, "year"), dayjs()]);

    const handleProjectChange = (event) => {
        setProject(event.target.value)
    }

    const [type, setType] = useState("유형별");
    // 변수명 수정할 것

    const handleTypeChange = (event, type) => {
        if (type !== null) {
            setType(type);
        }
    };


    useEffect(() => {
        const start = value.map(v => new Date(dayjs(v).format("MM-DD-YYYY")))[0];
        const end = value.map(v => new Date(dayjs(v).format("MM-DD-YYYY")))[1];

        const fetchData = async () => {
            const res = await axios.get(`/incidentdata?start=${start}&end=${end}`);
            if(res.data.success){
            const data = res.data.data
            setIRData(data);
            setProjectList(["전체", ...new Set(res.data.data.filter(v=>v.ugenProjectName !== null).map(v => v.ugenProjectName))].filter(v=>v!==undefined));

            }
        }
        fetchData();
    }, [value])

    useEffect(() => {

        const filteredData =project === "전체"? IRData.filter(v=>v.IRIncidentTypeText !== null ):IRData.filter(v=>v.IRIncidentTypeText !== null && v.ugenProjectName === project)
        
        const incidentTypeCounts =filteredData.reduce((acc, item) => {
            const incidentType = item.IRIncidentTypeText;
            acc[incidentType] = (acc[incidentType] || 0) + 1;
            return acc;
        }, {});

        // Convert the object into an array for the pie chart
        const pieDataArray = Object.entries(incidentTypeCounts).map(([id, value]) => ({
            id,
            value,
        }));

        

        // Update the state with the processed data
        setPieData(pieDataArray);
    }, [IRData, project])

    useEffect(() => {
        const filteredData =project === "전체"? IRData.filter(v=>v.IRIncidentTypeText !== null ):IRData.filter(v=>v.IRIncidentTypeText !== null && v.ugenProjectName === project)

        if (type === "유형별") {

            const incidentTypeCounts = filteredData.reduce((acc, item) => {
                const incidentType = item.RiskCategoryDPK;
                acc[incidentType] = (acc[incidentType] || 0) + 1;
                return acc;
            }, {});

            // Convert the object into an array for the pie chart
            const barDataArray = Object.entries(incidentTypeCounts).map(([id, value]) => ({
                id,
                value,
            }));
            setBarData(barDataArray);
        } else if (type === "장소별") {

            const IRTypeCounts = filteredData.filter((c) => c.HSEProjectWBSDPK)
                .reduce((acc, item) => {
                    const IRType = item.HSEProjectWBSDPK
                    acc[IRType] = (acc[IRType] || 0) + 1;
                    return acc;
                }, {})

            const barDataArray = Object.entries(IRTypeCounts).map(([id, value]) => ({
                id,
                value,
            }));

            setBarData(barDataArray)

        } else if (type === "부위별") {

            const incidentTypeCounts = filteredData.reduce((acc, item) => {
                const incidentType = item.IRInjuredPart;
                acc[incidentType] = (acc[incidentType] || 0) + 1;
                return acc;
            }, {});

            // Convert the object into an array for the pie chart
            const barDataArray = Object.entries(incidentTypeCounts).map(([id, value]) => ({
                id,
                value,
            }));
            setBarData(barDataArray);
        }

    }, [IRData, type])

    return (
        <>


<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: '6px', marginTop:-56 }}>
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
        <SingleInputDateRangeField
            label=""
            value={value}
            onChange={(newValue) => setValue(newValue)}
            className='daterange2'
            style={{ marginRight: '10px', fontSize: '0.8rem', height: '30px', fontWeight: 600 }}
        />
    </LocalizationProvider>
    <DropDownList
        style={{
            width: "180px",
            fontSize: '0.7rem',
            height: '30px',
            fontWeight: 600
        }}
        data={projectList}
        value={project}
        onChange={handleProjectChange}
    />
</div>

            <GridLayout style={{ height: height }}
                rows={[
                    {
                        height: '100%',
                    },
                ]}
                cols={[
                    {
                        width: '40%',
                    },
                    {
                        width: '60%',
                    },


                ]}
                gap={{
                    rows: 10,
                    cols: 0,
                }}>

                <GridLayoutItem row={1} col={1} style={{ position: 'relative' }}>
                    <div style={{ fontSize: "12px", textAlign: "center", marginBottom: "5px" }}><b>재해 유형</b></div>
                    <SFPieChart pieData={pieData} />
                </GridLayoutItem>
                <GridLayoutItem row={1} col={2} style={{ position: 'relative' }}>
                    <div style={{ position: 'absolute', transform: 'translateY(-10%)', zIndex: 100 , right:5}} className='manRB' >
                        <ThemeProvider theme={theme}>
                            <ToggleButtonGroup2 size="small" onChange={handleTypeChange} value={type} exclusive aria-label="baseunit">
                                <ToggleButton2 value="유형별" aria-label='유형별' >
                                    유형별
                                </ToggleButton2>
                                <ToggleButton2 value="장소별" aria-label="장소별">
                                    장소별
                                </ToggleButton2>
                                <ToggleButton2 value="부위별" aria-label="부위별">
                                    부위별
                                </ToggleButton2>
                            </ToggleButtonGroup2>
                        </ThemeProvider>
                    </div>

                    <SFBarChart id="disasterBar" height={height} data={barData} maxWidth={120}/>
                </GridLayoutItem>

            </GridLayout>

        </>
    )
}

export default DisasterStatus
