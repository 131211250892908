import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import axios from "../../../common/axios"
import {
  Card, CardTitle, CardBody, CardImage,
  GridLayout,
  GridLayoutItem,
} from "@progress/kendo-react-layout";
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { Divider, List, ListItem, ListItemText } from '@mui/material'
import moment from 'moment'
import dayjs from 'dayjs';
import PieChart from './overviewPieChart';

const ToggleButton2 = styled(MuiToggleButton)(() => ({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: '#FF6F61',
  },
  '&.MuiToggleButton-root': {
    fontSize: '0.6rem',
    fontFamily: 'Pretendard-R',
    padding: 0,
    width: 60,
    borderRadius: '5px 5px 5px 5px!important',
    border: '1px solid rgba(0, 0, 0, 0.12)!important'

  }
}));

const ToggleButtonGroup2 = styled(MuiToggleButtonGroup)(() => ({
  '&.MuiToggleButtonGroup-root': {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1)
    }
  }
}));


const theme = createTheme({
  palette: {
    text: {
      primary: '#00ff00',
    },
  },
});

const Equip = ({ drdata, height, setdrdata }) => {

  const location = useLocation();
  const pathname = location.pathname.split('/').pop();
  const { project_code } = useParams();


  const [equipdata, setEquipData] = useState([])

  const [dateValue2, setDateValue2] = useState(new Date(dayjs().add(-1, 'days')));


  const [selectedValue2, setSelectedValue2] = React.useState("종류");

  const handleChange2 = (event, selectedValue2) => {
    if (selectedValue2 !== null) {
      setSelectedValue2(selectedValue2);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {

        const res = await axios.get(`/getequipData?project=${project_code}&selectedValue2=${selectedValue2}`)
        setEquipData(res.data.data);

      } catch (userError) {
        console.error("Error fetching user data:", userError);
        // Handle error in fetching user data
      }
    }
    fetchData()

  }, [selectedValue2, project_code]);



  const equipPieData = (equipdata.map(item => {
    // 현재 항목에서 "date" 값을 가져옴
    const date = item.date;

    // "date"와 "none"을 제외한 키와 값을 추출하여 배열에 추가
    const newData = Object.keys(item)
      .filter(key => key !== "date" && key !== "none" && item[key] > 0)
      .map(key => ({ date, id: key, value: item[key] }));

    return newData;
  })).flat();


  const totalSum2 = _.sumBy(equipPieData, "value")

  return (
    <>
      <GridLayout style={{ height: height }}
        rows={[
          {
            height: '100%',
          },
        ]}
        cols={[
          {
            width: '100%',
          }
        ]}
        gap={{
          rows: 10,
          cols: 0,
        }}>

        <GridLayoutItem row={1} col={1} style={{ position: 'relative' }}>
          {pathname === "overview" ? (
            <>
              <div style={{ position: 'absolute', transform: 'translateY(-50%)', zIndex: 100 }} className='manRB'>
                <ThemeProvider theme={theme}>
                  <ToggleButtonGroup2 size="small" onChange={handleChange2} value={selectedValue2} exclusive aria-label="baseunit">
                    <ToggleButton2 value="종류" aria-label='종류' >
                      종류
                    </ToggleButton2>
                    <ToggleButton2 value="업체별" aria-label="업체별">
                      업체별
                    </ToggleButton2>
                  </ToggleButtonGroup2>
                </ThemeProvider>
              </div>
            </>) : (
            <>
              <div style={{ position: 'absolute', transform: 'translateY(-50%)', zIndex: 100 }} className='manRB'>
                <ThemeProvider theme={theme}>
                  <ToggleButtonGroup2 size="small" onChange={handleChange2} value={selectedValue2} exclusive aria-label="baseunit">
                    <ToggleButton2 value="종류" aria-label='종류' >
                      종류
                    </ToggleButton2>
                    <ToggleButton2 value="업체별" aria-label="업체별">
                      업체별
                    </ToggleButton2>
                  </ToggleButtonGroup2>
                </ThemeProvider>
              </div>
            </>
          )}
          <PieChart data={equipPieData} text="대" totalSum={totalSum2} dateValue={dateValue2} setDateValue={setDateValue2} />
        </GridLayoutItem>

      </GridLayout>

    </>
  );

}

export default Equip
