import React, { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { QualityInspCadsData } from "../../../../atom/qualityState";
import { DataGrid } from "@mui/x-data-grid";
import _ from "lodash";
import dayjs from "dayjs";
const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

const InspectionCadastralStatus = ({
  height,
  targetProject,
  dateValue,
  targetVendor,
}) => {
  const data = useRecoilValue(QualityInspCadsData);

  const table_data = useMemo(() => {
    let copy_data = [...data];

    if (targetProject.value !== "all") {
      copy_data = copy_data.filter(
        (c) => c.project_projectnumber === targetProject.value
      );
    }

    if (targetVendor) {
      copy_data = copy_data.filter(
        (c) => c.uuu_user_company === targetVendor.label
      );
    }

    const [startDate, endDate] = dateValue;

    if (startDate && endDate) {
      copy_data = [...copy_data].filter((c) => {
        const { QPActDate } = c;

        const QPActDate_dayjs = dayjs(QPActDate, "MM-DD-YYYY");

        return startDate <= QPActDate_dayjs && QPActDate_dayjs <= endDate;
      });
    }

    const vendor_group = _(copy_data)
      .groupBy("uuu_user_company")
      .map((objs, key) => {
        const count = objs.length;
        const open = objs.filter((c) => c.status === "Pending").length;
        const close = objs.filter((c) => c.status === "Closed").length;

        console.log(key, returnZeroIfInvalid((close / count) * 100).toFixed(1));

        return {
          vendorname: key,
          open: open + " 건",
          close: close + " 건",
          percent: {
            count: count + " 건",
            percent: returnZeroIfInvalid((close / count) * 100).toFixed(1),
          },
        };
      })
      .value()
      .map((c, i) => {
        return { id: i, ...c };
      });

    return vendor_group;
  }, [targetProject, dateValue, data, targetVendor]);

  return (
    <DataGrid
      rows={table_data}
      columns={columns}
      hideFooter={true}
      style={{ maxHeight: height }}
      sx={{
        "& .MuiDataGrid-cell": {
          fontFamily: "Inter",
          fontSize: "14px",
          color: "black",
        },
        "& .MuiDataGrid-columnHeaders": {
          fontFamily: "Inter",
          fontWeight: 700,
          backgroundColor: "#F9FAFB",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontFamily: "Inter",
          fontWeight: 700,
        },
      }}
    />
  );
};

export default InspectionCadastralStatus;

const columns = [
  { field: "vendorname", headerName: "협력사명", flex: 1 },
  { field: "open", headerName: "OPEN", width: 100 },
  { field: "close", headerName: "CLOSED", width: 100 },
  {
    field: "percent",
    headerName: "발행건수(조치율)",
    width: 120,
    renderHeader: (params) => (
      <div
        className="wrapText"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          height: "100%",
        }}
      >
        <div style={{ height: 24 }}>발행건수</div>
        <div>(조치율)</div>
      </div>
    ),
    renderCell: (params) => {
      return (
        <div
          className="wrapText"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <div>{params.value.count}</div>
          <div>({params?.value?.percent ?? 0} %)</div>
        </div>
      );
    },
  },
];

const returnZeroIfInvalid = (value) => {
  if (value === Infinity || isNaN(value) || value === false) {
    return 0;
  }
  return value;
};
