import React, { useState, useEffect, useMemo } from 'react';
import { TileLayout } from "@progress/kendo-react-layout";
import { DashboardLayout } from '../../../layout/Layout';
import useBreakpoint from "use-breakpoint";
import useWindowDimensions from '../../common/useWindowDimensions';
import Milestone from './milstoneTile';
import axios from "../../../common/axios";
import { useParams } from 'react-router-dom';
import ManPower from '../manpower/manPeriod';
import Intro from './projectInfoTile';
import Man from './manTile';
import Equip from './equipTile';
import Progress from './progressTile';
import CostTile from './costTile';
import SafetyTile from './safetyTile';
import QualityTile from './qualityTile';
import dayjs from "dayjs";

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280, };

const ProjectOverviewPage = () => {

  const [userType, setUserType] = useState("Yes")

  useEffect(() => {
    const fetchData = async () => {
      try {

        const res = await axios.get(`/checkuserType`);
        if (res.data.success) {
          setUserType(res.data.data)

        }
      } catch (error) {
        console.error('데이터 가져오기 중 오류 발생:', error);
      }
    };
    fetchData();
  }, []);

  const [tiles, setTiles] = useState([])
  const { project_code } = useParams();
  const { height, width } = useWindowDimensions();
  const [grid, setGrid] = useState(
    {
      mobile:
        [

          {
            col: 1,
            colSpan: 16,
            rowSpan: 6,

          },
          {
            col: 1,
            colSpan: 16,

            rowSpan: 2,
          },
          {
            col: 1,
            colSpan: 16,

            rowSpan: 2,
          },
          {
            col: 1,
            colSpan: 16,

            rowSpan: 2,
          },

          {
            col: 1,
            colSpan: 16,
            rowSpan: 2,

          },


          {
            col: 1,
            colSpan: 16,
            rowSpan: 2,

          },


          {
            col: 1,
            colSpan: 16,
            rowSpan: 2,

          },
          {
            col: 1,
            colSpan: 16,
            rowSpan: 2,

          }



        ]
      ,
      tablet:
        [

          {
            col: 1,
            colSpan: 6,
            rowSpan: 2,

          },
          {
            col: 7,
            colSpan: 6,
            rowSpan: 2,

          },
          {
            col: 13,
            colSpan: 6,
            rowSpan: 2,

          },
          {
            col: 1,
            colSpan: 9,
            rowSpan: 2,

          },
          {
            col: 10,
            colSpan: 9,
            rowSpan: 2,

          },
          {
            col: 1,
            colSpan: 6,
            rowSpan: 2,

          },
          {
            col: 7,
            colSpan: 6,
            rowSpan: 2,

          },
          {
            col: 13,
            colSpan: 6,
            rowSpan: 2,

          },
        ]
      ,
      desktop:
        [

          {
            col: 1,
            colSpan: 4,
            rowSpan: 6,
          },
          {
            col: 5,
            colSpan: 12,
            rowSpan: 2,

          },
          {
            col: 5,
            colSpan: 4,
            rowSpan: 2,

          },
          {
            col: 9,
            colSpan: 4,
            rowSpan: 2

          },
          {
            colSpan: 4,
            rowSpan: 2,
          },
          {
            col: 5,
            colSpan: 3,
            rowSpan: 2,

          },
          {
            col: 8,
            colSpan: 3,
            rowSpan: 2,

          },
          {
            col: 11,
            colSpan: 6,
            rowSpan: 2,
          },
        ]
    }


  );

  useEffect(() => {
    updateLayout(userType);
  }, [userType]);

  const updateLayout = (userType) => {
    let newGrid, newTiles;
    if (userType === "Yes") {
      newGrid = {
        mobile:
          [

            {
              col: 1,
              colSpan: 16,
              rowSpan: 6,

            },
            {
              col: 1,
              colSpan: 16,

              rowSpan: 2,
            },
            {
              col: 1,
              colSpan: 16,

              rowSpan: 2,
            },
            {
              col: 1,
              colSpan: 16,

              rowSpan: 2,
            },

            {
              col: 1,
              colSpan: 16,
              rowSpan: 2,

            },


            {
              col: 1,
              colSpan: 16,
              rowSpan: 2,

            },


            {
              col: 1,
              colSpan: 16,
              rowSpan: 2,

            },
            {
              col: 1,
              colSpan: 16,
              rowSpan: 2,

            }



          ]
        ,
        tablet:
          [

            {
              col: 1,
              colSpan: 6,
              rowSpan: 2,

            },
            {
              col: 7,
              colSpan: 6,
              rowSpan: 2,

            },
            {
              col: 13,
              colSpan: 6,
              rowSpan: 2,

            },
            {
              col: 1,
              colSpan: 9,
              rowSpan: 2,

            },
            {
              col: 10,
              colSpan: 9,
              rowSpan: 2,

            },
            {
              col: 1,
              colSpan: 6,
              rowSpan: 2,

            },
            {
              col: 7,
              colSpan: 6,
              rowSpan: 2,

            },
            {
              col: 13,
              colSpan: 6,
              rowSpan: 2,

            },
          ]
        ,
        desktop:
          [

            {
              col: 1,
              colSpan: 4,
              rowSpan: 6,
            },
            {
              col: 5,
              colSpan: 12,
              rowSpan: 2,

            },
            {
              col: 5,
              colSpan: 4,
              rowSpan: 2,

            },
            {
              col: 9,
              colSpan: 4,
              rowSpan: 2

            },
            {
              colSpan: 4,
              rowSpan: 2,
            },
            {
              col: 5,
              colSpan: 3,
              rowSpan: 2,

            },
            {
              col: 8,
              colSpan: 3,
              rowSpan: 2,

            },
            {
              col: 11,
              colSpan: 6,
              rowSpan: 2,
            },
          ]
      };
      newTiles = [{
        header: "Project 개요",
        body: <Intro height={6 * height / 6.5 - 80} />,

      }, {

        header: "Project Milestone",
        body: <Milestone height={2 * (height / 6.5) - 60} />,
        // body: "",
        // className: 'milestone'
      },

      {
        header: "공정현황",
        body: <Progress height={2 * height / 6.5 - 80} />,

      },
      {

        header: "원가현황",
        body: <CostTile height={2 * height / 6.5 - 80} />,
      },
      {
        header: "안전활동",
        body: <SafetyTile height={height} />,
      },

      {
        header: "인원",
        body: <Man drdata={drdata} height={2 * height / 6.5 - 80} datevalue={datevalue} setdrdata={setdrdata} />,
      },
      {
        header: "장비",
        body: <Equip height={2 * height / 6.5 - 80} drdata={drdata} setdrdata={setdrdata} />,
      },

      {
        header: "품질활동",
        body: <QualityTile height={height} />,
      }
      ];
    } else {
      newGrid = {
        mobile:
          [

            {
              col: 1,
              colSpan: 4,
              rowSpan: 6,

              //개요
            },
            {
              col: 5,
              colSpan: 12,
              rowSpan: 2,

              //마일스톤
            },
            {
              col: 5,
              colSpan: 5,
              rowSpan: 2,

              //공정
            },
            {
              col: 10,
              colSpan: 7,
              rowSpan: 2,

              //품질
            },

            {
              col: 5,
              colSpan: 4,
              rowSpan: 2,

              //인원
            },
            {
              col: 9,
              colSpan: 4,
              rowSpan: 2,

              //장비
            },
            {
              col: 11,
              colSpan: 6,
              rowSpan: 2,

              //안전
            },
          ]
        ,
        tablet:
          [

            {
              col: 1,
              colSpan: 4,
              rowSpan: 6,

              //개요
            },
            {
              col: 5,
              colSpan: 12,
              rowSpan: 2,

              //마일스톤
            },
            {
              col: 5,
              colSpan: 5,
              rowSpan: 2,

              //공정
            },
            {
              col: 10,
              colSpan: 7,
              rowSpan: 2,

              //품질
            },

            {
              col: 5,
              colSpan: 4,
              rowSpan: 2,

              //인원
            },
            {
              col: 9,
              colSpan: 4,
              rowSpan: 2,

              //장비
            },
            {
              col: 11,
              colSpan: 6,
              rowSpan: 2,

              //안전
            },
          ]
        ,
        desktop: [

          {
            col: 1,
            colSpan: 4,
            rowSpan: 6,

            //개요
          },
          {
            col: 5,
            colSpan: 12,
            rowSpan: 2,

            //마일스톤
          },
          {
            col: 5,
            colSpan: 4,
            rowSpan: 2,

            //공정
          },
          {
            col: 9,
            colSpan: 8,
            rowSpan: 2,
            //안전활동
          },

          {
            col: 5,
            colSpan: 3,
            rowSpan: 2,

            //인원
          },
          {
            col: 8,
            colSpan:3,
            rowSpan: 2,

            //장비
          },
          {
            col: 11,
            colSpan: 6,
            rowSpan: 2,

            //안전
          },
        ]
      };
      newTiles = [{
        header: "Project 개요",
        body: <Intro height={6 * height / 6.5 - 80} />,

      }, {

        header: "Project Milestone",
        // body: <Milestone height={2 * (height / 6.5) - 60} />,[]
        body: "",
        // className: 'milestone'
      },

      {
        header: "공정현황",
        body: <Progress height={2 * height / 6.5 - 80} />,
      },
      {

        header: "품질활동",
        body: <QualityTile height={height} />,
      },

      {

        header: "인원",
        body: <Man  height={2 * height / 6.5 - 80}  />,
        // body: "",
      },
      {

        header: "장비",
        body: <Equip height={2 * height / 6.5 - 80} drdata={drdata} setdrdata={setdrdata} />,
        // body: "",
      }, 
      {
        header: "안전활동",
        body: <SafetyTile height={height} />,
      }
        ,
      ];
    }
    setGrid(newGrid);
    setTiles(newTiles);
  };


  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop')


  const defaultvalue = new Date()
  const [positiondata, setPositionData] = useState(grid['desktop']);
  useEffect(() => {
    setPositionData(grid[breakpoint])
  }, [breakpoint, grid]);

  const handleReposition = (e) => {
    setPositionData(e.value);
  };


  console.log(grid,breakpoint )
  console.log(positiondata )

  const [drdata, setdrdata] = useState([]);

  const [datevalue, setValue] = useState(defaultvalue);

  useEffect(() => {
    const fetchData = async () => {

      let body =
      {
        "bpname": "Daily Report",
        "lineitem": "yes",
        "filter_criteria": {
          "join": "AND",
          "filter": [
            {
    
              "field": "ReportingDO",
              "value":  dayjs().add(-1,"days").format("MM-DD-YYYY"),
              "value2": dayjs().format("MM-DD-YYYY"),
              "condition_type": "range"
            },
    
            {
              "field": "status",
              "value": "Submitted",
              "condition_type": "neq"
            }
            ,
    
            {
              "field": "status",
              "value": "Submitted",
              "condition_type": "neq"
            }
            ,
    
            {
              "field": "status",
              "value": "Revision_Required",
              "condition_type": "neq"
            }    ,
    
            {
              "field": "status",
              "value": "Pending",
              "condition_type": "neq"
            }
          ]
        }
      }

      const res = await axios.post(`/getbprecordsdash?path=${project_code}`, body);

      setdrdata(res.data.data.data)

    }
    fetchData();
  }, [project_code]);



  return (
    <DashboardLayout>

      <TileLayout
        className='projectpage'
        style={{ color: '#F1F2F5 ' }}
        columns={16}
        rowHeight={height / 6.5}
        positions={positiondata}
        gap={{
          rows: 10,
          columns: 10
        }}
        items={tiles}
        autoFlow="row dense"
        onReposition={handleReposition}
      // style={{marginTop:20}}
      />
    </DashboardLayout>
  );

};

export default ProjectOverviewPage
