import React, { useState, useEffect } from 'react'
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout'
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import SCurveChart from '../monthlyMaterial/MonthlyChart';
import { DropDownList } from "@progress/kendo-react-dropdowns"
import _ from 'lodash';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const WeekltyMaterial = ({ height, mData1, mData2, mData3 }) => {
  const tlist = ["전체", "PIPE", "FLANGE", "FITTING", "VALVE", "OTHERS"]
  // const [dlist, setdlist] = useState(["SKU"]);
  const dlist = ["SKU", "물량"]
  const [tstate, settState] = useState(tlist[0]);
  const [dstate, setdState] = useState(dlist[0]);
  const [chartdata, setChartdata] = useState([]);

  const defaultvalue = new Date()
  const [datevalue, setDateValue] = React.useState([
    dayjs(dayjs(defaultvalue).subtract(1, 'weeks').format("YYYY-MM-DD")),
    dayjs(dayjs(defaultvalue).format("YYYY-MM-DD")),
  ]);

  const handletChange = (event) => {
    settState(event.target.value);
  };

  const handledChange = (event) => {
    setdState(event.target.value);
  };


  useEffect(() => {

    const startDate = dayjs(datevalue[0]).format("YYYY-MM-DD");
    const endDate = dayjs(datevalue[1]).format("YYYY-MM-DD");
    const dateRange = createDateRange(startDate, endDate);

    const totalData = tstate !== "" && tstate === "전체" ? [...mData1, ...mData2] : [...mData1, ...mData2].filter(v => v.umatSHICategoryPD === tstate);

    const mReq = totalData.filter(v => v.uuu_creation_date !== null)
      .map((v) => ({ category: dayjs(v.uuu_creation_date).format("YYYY-MM-DD"), "이관 신청": dstate === "SKU" ? 1 : v.SHIMatplanQtyDA }));

    const mIn = totalData.filter(v => v.uuu_creation_date !== null)
      .map((v) => ({ category: dayjs(v.uuu_creation_date).format("YYYY-MM-DD"), "이관 입고 완료": dstate === "SKU" ? 1 : v.SHIMatinqnty }));

    //////이관 출고
    const linedatas = mData3
      .filter(v => v._bp_lineitems !== undefined)
      .reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { uuu_creation_date: v.uuu_creation_date }))), [])
      .filter(v => v.SHIMatoutType === "이관")

    const mOut = linedatas.filter(v => v.uuu_creation_date !== null)
      .map((v) => ({ category: dayjs(v.uuu_creation_date).format("YYYY-MM-DD"), "이관 출고 완료": dstate === "SKU" ? 1 : v.SHIMatoutQnty }));


    let chartDataInter =
      _([...mReq, ...mIn, ...mOut].filter(v => v.category !== undefined))
        .groupBy("category")
        .map((objs, key) => ({
          "category": key,
          "이관 신청": _.sumBy(objs, "이관 신청"),
          "이관 입고 완료": _.sumBy(objs, "이관 입고 완료"),
          "이관 출고 완료": _.sumBy(objs, "이관 출고 완료"),
        }))
        .value();

    // chartDataInter를 "category" 기준으로 날짜순으로 정렬
    let sortedChartDataInter = _.sortBy(chartDataInter, item => dayjs(item.category));

    // "이관 입고 완료"와 "이관 출고 완료"의 누적값을 계산
    let chartData = sortedChartDataInter.reduce((acc, item, index) => {
      // 첫 번째 항목의 경우, 누적값은 현재 값과 동일
      if (index === 0) {
        acc.push({
          ...item,
          "이관 입고 완료 누적": item["이관 입고 완료"],
          "이관 출고 완료 누적": item["이관 출고 완료"]
        });
      } else {
        // 이전 항목의 누적값에 현재 값 추가
        let lastAccumulatedItem = acc[index - 1];
        acc.push({
          ...item,
          "이관 입고 완료 누적": lastAccumulatedItem["이관 입고 완료 누적"] + item["이관 입고 완료"],
          "이관 출고 완료 누적": lastAccumulatedItem["이관 출고 완료 누적"] + item["이관 출고 완료"]
        });
      }
      return acc;
    }, []);
    // chartData를 날짜 범위로 필터링
    chartData = _.sortBy(chartData, "category").filter(item => {
      const itemDate = dayjs(item.category, "YYYY-MM-DD");
      return itemDate.isSameOrAfter(startDate) && itemDate.isSameOrBefore(endDate);
    });

    // chartData를 역순으로 순회하며 각 날짜별 마지막 누적값을 저장
    const lastAccumValues = {};
    chartData.slice().reverse().forEach(item => {
      if (!lastAccumValues.hasOwnProperty('이관 입고 완료 누적')) {
        lastAccumValues['이관 입고 완료 누적'] = item['이관 입고 완료 누적'];
      }
      if (!lastAccumValues.hasOwnProperty('이관 출고 완료 누적')) {
        lastAccumValues['이관 출고 완료 누적'] = item['이관 출고 완료 누적'];
      }
    });

    // dateRange를 순회하며 filledChartData 생성
    const filledChartData = dateRange.map(date => {
      const existingItem = chartData.find(item => item.category === date);
      if (existingItem) {
        // 현재 날짜의 데이터가 있으면, 그 데이터 사용
        return existingItem;
      } else {
        // 현재 날짜 이전의 가장 최근 누적값을 사용
        return {
          category: date,
          "이관 입고 완료": 0,
          "이관 출고 완료": 0,
          "이관 입고 완료 누적": lastAccumValues['이관 입고 완료 누적'],
          "이관 출고 완료 누적": lastAccumValues['이관 출고 완료 누적'],
        };
      }
    });
    setChartdata(_.sortBy(filledChartData, "category"))



  }, [mData1, , mData2, mData3, tstate, dstate, datevalue]);


  function createDateRange(startDate, endDate) {
    const dates = [];
    let currentDate = dayjs(startDate);

    while (currentDate.isSameOrBefore(endDate)) {
      dates.push(currentDate.format("YYYY-MM-DD"));
      currentDate = currentDate.add(1, 'day');
    }

    return dates;
  }



  useEffect(() => {

    var root = am5.Root.new("transferM");

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        panX: true,
        wheelY: "zoomX",
        layout: root.verticalLayout
      })
    );

    let tooltip = am5.Tooltip.new(root, {});

    chart.plotContainer.set("tooltipPosition", "pointer");
    chart.plotContainer.set("tooltipText", "a");
    chart.plotContainer.set("tooltip", tooltip);


    tooltip.label.adapters.add("text", function (text, target) {
      text = "";
      let i = 0;
      chart.series.each(function (series) {
        let tooltipDataItem = series.get("tooltipDataItem");


        if (tooltipDataItem) {
          if (i === 0) {
            text += "[bold]" + tooltipDataItem.dataContext.category + "\n";
          }

          if (i !== 0) {
            text += "\n";
          }

          text +=
            "[" +
            series.get("stroke") +
            "]●[/] [width:100px]" +
            series.get("name") +
            ":[/] " +
            Number(
              (tooltipDataItem.get("valueY") ?? 0).toFixed(2)
            ).toLocaleString() +
            " ";
          // (unit ? unit : series.get("name").includes("시간") ? "h" : "%");
        }
        i++;
      });
      return text;
    });

    tooltip.get("background").setAll({
      stroke: am5.color(0x000000),
      strokeOpacity: 0.8,
      fill: am5.color(0xffffff),
      fillOpacity: 0.8,
    });
    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "zoomX",
      })
    );

    cursor.lineX.setAll({
      stroke: "#525252",
      strokeWidth: 1,
      strokeDasharray: [5, 5],
    });

    cursor.lineY.setAll({
      stroke: "#525252",
      strokeWidth: 1,
      strokeDasharray: [5, 5],
    });

    // cursor.lineY.set("visible", false);

    // let data = [...data];

    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );

    legend.markers.template.setAll({
      width: 10,
      height: 10,
    });

    legend.labels.template.setAll({
      fontSize: "0.7rem",
      fontWeight: "400",
      width: 90,
      //   fill: "#fff",
    });

    // Define data
    var data = chartdata.map((v) => ({ ...v, date: new Date(v.category).getTime() }))
    // Craete Y-axes
    var yAxis1 = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        height: am5.percent(50),
        x: am5.percent(100),
        min: 0,
        centerX: am5.percent(100),
        renderer: am5xy.AxisRendererY.new(root, {
          grid: {
            disabled: true,
          },
        })
      })
    );


    yAxis1.axisHeader.children.push(am5.Label.new(root, {
      text: "이관 입고",
      fontWeight: "500"
    }));

    var yAxis2 = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        height: am5.percent(50),
        min: 0,
        x: am5.percent(100),
        centerX: am5.percent(100),
        renderer: am5xy.AxisRendererY.new(root, {
          grid: {
            disabled: true,
          },
        })
      })
    );

    yAxis2.axisHeader.set("paddingTop", 10);
    yAxis2.axisHeader.children.push(am5.Label.new(root, {
      text: "이관 출고",
      fontWeight: "500"
    }));


    var yAxis3 = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        height: am5.percent(50),
        x: am5.percent(100),
        centerX: am5.percent(100),
        renderer: am5xy.AxisRendererY.new(root, {
          opposite: true,
          // strokeOpacity: 1,
          // strokeWidth: 1,
          // stroke: "rgba(224, 224, 224, 0.5)",
          // opposite:true
        })
      })
    );


    yAxis3.axisHeader.children.push(am5.Label.new(root, {
      text: "",
      fontWeight: "500"
    }));

    var yAxis4 = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        height: am5.percent(50),
        x: am5.percent(100),
        centerX: am5.percent(100),
        renderer: am5xy.AxisRendererY.new(root, {
          opposite: true,
          // strokeOpacity: 1,
          // strokeWidth: 1,
          // stroke: "rgba(224, 224, 224, 0.5)",
          // opposite:true
        })
      })
    );

    yAxis4.axisHeader.set("paddingTop", 10);
    yAxis4.axisHeader.children.push(am5.Label.new(root, {
      text: "",
      fontWeight: "500"
    }));




    // Stack Y-axes
    chart.leftAxesContainer.set("layout", root.verticalLayout);

    let xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
      strokeOpacity: 0,
      // strokeWidth: 1,
      // stroke: "rgba(224, 224, 224, 0.5)",
    });

    // Create X-Axis
    var xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        minGridDistance: 30,
        cellStartLocation: 0.1,
        cellEndLocation: 0.9,
        strokeOpacity: 0,
        baseInterval: { timeUnit: "day", count: 1 },
        renderer: xRenderer
      })
    );


    xRenderer.grid.template.setAll({
      location: 1,
      strokeOpacity: 0,
    });

    xAxis.get("dateFormats")["day"] = "MM-dd";

    xRenderer.labels.template.setAll({
      oversizedBehavior: "wrap",
      textAlign: "center",
      fill: "#525252",
      maxWidth: 50,
      fontSize: "0.8rem",
      minScale: 0.6,

    });


    // Create series
    function createSeries(name, field, color, yAxis) {
      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          stroke: color,
          fill: color,
          valueYField: field,
          valueXField: "date",
          clustered: true
        })
      );
      series.data.setAll(data);
      series.appear();

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 0,
          sprite: am5.Label.new(root, {
            // text: "{valueY}",
            fill: root.interfaceColors.get("alternativeText"),
            centerY: 0,
            centerX: am5.p50,
            populateText: true,
          }),
        });
      });

      legend.data.push(series);
    };


    createSeries("이관 신청", "이관 신청", "#68AAAC", yAxis1);
    createSeries("이관 입고 완료", "이관 입고 완료", "#1F3864", yAxis1);
    createSeries("이관 출고 완료", "이관 출고 완료", "#1F3864", yAxis2);

    const makeParetoSeries = (name, field, color, yAxis) => {
      var series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          stroke: color,
          fill: color,
          valueYField: field,
          valueXField: "date",
        })
      );
      series.data.setAll(data);
      series.appear();

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 0,
          sprite: am5.Label.new(root, {
            // text: "{valueY}",
            fill: root.interfaceColors.get("alternativeText"),
            centerY: 0,
            centerX: am5.p50,
            populateText: true,
          }),
        });
      });

      legend.data.push(series);
    };


    makeParetoSeries("이관 입고 완료 누적", "이관 입고 완료 누적", "#E37912", yAxis3)
    makeParetoSeries("이관 출고 완료 누적", "이관 출고 완료 누적", "#E37912", yAxis4)


    return () => {
      root && root.dispose();
    };
  }, [chartdata, height]);

  return (
    <>
      <GridLayout style={{ height: height }}
        rows={[
          {
            height: '10%',
          },
          {
            height: '90%',
          },
        ]}
        cols={[
          {
            width: '100%',
          },


        ]}
        gap={{
          rows: 10,
          cols: 0,
        }}>


        <GridLayoutItem row={1} col={1} style={{ position: 'relative' }}>
          <div style={{ display: "flex", fontSize: '0.8rem', top: 0, zIndex: 9999, justifyContent: "flex-end" }}>
            <DropDownList
              style={{
                width: "100px", fontSize: '0.7rem', height: 30, fontWeight: 600, marginRight: "10px"
              }}
              data={dlist}
              value={dstate}
              onChange={handledChange}
            />
                       <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['SingleInputDateRangeField']}>
                                <div style={{ minWidth: 200 }}>
                                    <DateRangePicker
                                        slots={{ field: SingleInputDateRangeField }}
                                        name="allowedRange"
                                        format='YYYY-MM-DD'
                                        value={datevalue}
                                        onChange={(newValue) => setDateValue(newValue)}
                                        className='daterange daterangecolor'
                                    />
                                </div>
                            </DemoContainer>
                        </LocalizationProvider>
            <DropDownList
              style={{
                width: "150px", fontSize: '0.7rem', height: 30, fontWeight: 600, marginLeft: "10px"
              }}
              data={tlist}
              value={tstate}
              onChange={handletChange}
            />
          </div>
        </GridLayoutItem>
        <GridLayoutItem row={2} col={1} >
          <div id="transferM" style={{ height: height * 0.88 }}></div>
        </GridLayoutItem>

      </GridLayout>

    </>
  )
}

export default WeekltyMaterial
