import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import 'hammerjs';
import axios from "../../../../common/axios";
import SCurveChart from '../../../common/SCurveChart';
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import { ComboBox } from "@progress/kendo-react-dropdowns";
import {
    loadMessages,
} from "@progress/kendo-react-intl";
import loadCldrData from "../../../common/loadCldrData";
import krMessages from "../../../common/kr.json";
import { Circles } from 'react-loader-spinner';
import { ToggleButton, ToggleButtonGroup } from '../../../common/CustomToggleButton';
import {  DataGridPremium } from '@mui/x-data-grid-premium';
import styled from 'styled-components';  // styled-components를 import 합니다

loadCldrData();
loadMessages(krMessages, "ko-KR");

const StyledTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
  background-color: #f0f0f0;
  border-bottom: 1px solid #e0e0e0;
`;
const VProgressChartNew = ({ height, width }) => {

    const { project_code } = useParams();
    const [progressData, setProgressData] = useState([]);
    const [delayedActList, setDelayedActList] = useState([]);
    const [nextWeekActList, setNextWeekActList] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [checked, setChecked] = React.useState("월간");
    const [vendorLists, setVendorLists] = useState([]);
    const [loading, setLoading] = useState(true);


    const handleChangeX = (event, selectedValue) => {
        if (selectedValue !== null) {
            setChecked(selectedValue);
        }
    };

    useEffect(() => {
        setLoading(true);
        const fetchData = async (reportName) => {
            try {
                const res = await axios.get(`/getprogressv?project=${project_code}&value=${selectedVendor}`);
                setProgressData(checked === "월간" ? res.data.month : res.data.week);
                setVendorLists(res.data.vendorList)
                setLoading(false);
                setDelayedActList(res.data.delayedActList.map((v)=>({...v, id:v.record_no})))
                setNextWeekActList(res.data.nextWeekActList.map((v)=>({...v, id:v.record_no})))
            } catch (error) {
                console.error(`${reportName} 데이터 가져오기 중 오류 발생:`, error);
                return []; // 오류 발생 시 빈 배열 반환
            }
        };
        fetchData();
    }, [project_code, selectedVendor, checked]);


    const handleChange = (event) => {
        setSelectedVendor(event.target.value);
    };

    const columnItems = [
        {
            name: checked === "월간" ? "월간 실적" : "주간 실적",
            field_name: "EV2",
            color: "#2196F3",
        },

        {
            name: checked === "월간" ? "월간 계획" : "주간 계획",
            field_name: "PV2",
            color: "#C1CFDD",
        },

    ];

    const lineItems = [
        {
            name: "누적 실적",
            field_name: "CC_EV",
            color: "#0966B0",
        },
        {
            name: "누적 계획",
            field_name: "CC_PV",
            color: "#93A6BA",
        },

    ];

    if (loading) {
        return <div className="loadingContainer">
            <Circles ariaLabel="loading" color="#00BFFF" height={100} width={100} />
        </div>;;
    }

    const maxWidth = progressData && width * 0.8 / progressData.length;
    const columns = [
        {
            field: 'record_no',
            headerName: "레코드 번호",
            flex: 0.5,
            headerClassName: 'bold-header',
        },
        {
            field: 'activityName',
            headerName: "액티비티 + 스텝명",
            flex: 2,
            headerClassName: 'bold-header',
        },
        {
            field: 'PALPlanProgress',
            headerName: "계획 공정률(%)",
            flex: 1,
            headerClassName: 'bold-header',
        },
        {
            field: 'PALActualProgress',
            headerName: "실적 공정률(%)",
            flex: 1,
            headerClassName: 'bold-header',
        },
        {
            field: 'diff',
            headerName: "차이",
            flex: 0.5,
            headerClassName: 'bold-header',
        },
    ];

    const commonGridProps = {
        rows: [],
        columns: columns,
        density: "compact",
        rowHeight: 38,
        hideFooter: true,
        sx: {

            '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold', // 헤더 셀의 글자를 진하게 설정
                fontSize: 12,

            },
            '& .MuiDataGrid-cell': {
                fontSize: 12,
            },
        },
    };

    return (
        <>

            <GridLayout style={{ height: (height / 6.5) * 4 - 50 }}
                rows={[
                    { height: 50 },
                    { height: 'calc(100% - 50px)' } // 전체에서 10px를 뺀 만큼의 높이 설정
                ]}
                cols={[
                    {
                        width: '33.3%',
                    }, {
                        width: '33.3%',
                    },
                    {
                        width: '33.3%',
                    },
                ]}
                gap={{
                    rows: 0,
                    cols: 8,
                }}>

                <div style={{
                    width: "100px", float: 'right', width: 250, fontSize: '0.7rem', height: 30, fontWeight: 600, position: 'absolute', top: -12, left: 150
                }}>

                    <ComboBox
                        // style={{ maxWidth: '250px', flex: 1, marginRight: '10px' }}
                        data={vendorLists}
                        label='업체명'
                        value={selectedVendor}
                        onChange={handleChange}
                        // className='disc'
                    />
                </div>

                <GridLayoutItem col={1} row={1}>
                    <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
    
                            <ToggleButtonGroup size="small" onChange={handleChangeX} value={checked} exclusive aria-label="baseunit">
                                <ToggleButton value="월간" aria-label="weekly">
                                    월간
                                </ToggleButton>
                                <ToggleButton value="주간" aria-label="monthly">
                                    주간
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </div>
                </GridLayoutItem>

                <GridLayoutItem col={1} row={2}>
                    {progressData && (
                        <SCurveChart data={progressData} maxWidth={maxWidth} columnItems={columnItems} lineItems={lineItems} chart_id={"scurve"} height={(height / 6.5) * 4 - 90} category={"date"} lineLabel={true} />
                    )}
                </GridLayoutItem>

                <GridLayoutItem col={2} row={1}>
                <StyledTitle>지연 스텝 리스트</StyledTitle>
                </GridLayoutItem>
                <GridLayoutItem col={2} row={2}>
                <div style={{ height: (height / 6.5) * 4 - 100, width: '100%' }}>
                    <DataGridPremium 
                        {...commonGridProps}
                        rows={delayedActList}
                    />
                </div>
            </GridLayoutItem>
                <GridLayoutItem col={3} row={1}>
                <StyledTitle>차주 물량처리계획 스텝 리스트</StyledTitle>
                </GridLayoutItem>
                <GridLayoutItem col={3} row={2}>
                <div style={{ height: (height / 6.5) * 4 - 100, width: '100%' }}>
                    <DataGridPremium 
                        
                        pagination={false}
                        {...commonGridProps}
                        rows={nextWeekActList}
                    />
                </div>
            </GridLayoutItem>

            </GridLayout>

        </>
    );

}

export default VProgressChartNew
